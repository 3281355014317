/** @format */

import {Container, Text} from "@chakra-ui/react";
import React from "react";
import SEO from "../components/seo";

const NotFoundPage = () => {
  return (
    <Container>
      <Text>Sorry Page not Found</Text>
    </Container>
  );
};

export default NotFoundPage;

export const Head = () => <SEO title="Page Not Found" />;
